export default (theme) => ({
  parentCard: {
    margin: theme.spacing(2),
  },
  heading: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  subtext: {
    marginTop: '1%',
    fontSize: '1rem',
    whiteSpace: 'pre-line',
  },
  voiceCallImg: {
    width: '322px',
    height: '213px',
    top: '387px',
    left: '278px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  inputField: {
    margin: theme.spacing(1),
    '& input': {
      borderRadius: '20px',
    },
  },
  submitBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2),
    borderRadius: '10px',
  },
  submitedContacts: {
    alignItems: 'center',
  },
  InputLabel: {
    marginLeft: '5px',
  },
  tabCard: {
    width: '-webkit-fill-available',
  },
  btnRadius: {
    borderRadius: '20px',
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
    width: '50%',
  },
  voiceCallButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '40px',
    '& button': {
      backgroundColor: theme.palette.aqua.extraDeep,
    },
  },
  voiceCallContent: {
    width: '377px',
    marginTop: '24px',
  },
  title: {
    padding: '10px',
    fontSize: '30px',
    fontWeight: 800,
    lineHeight: '36px',
    letterSpacing: '0em',
    textAlign: 'center',
  },
  content: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.02em',
    textAlign: 'center',
    width: '377px',
    height: '95px',
  },

  noContactsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  disclaimer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '4%',
    width: '403px',
    height: '44px',
    justifyContent: 'center',
  },
  InfoIconStyle: {
    color: '#061E44',
  },
  contactRow: {
    marginBottom: 10,
    borderRadius: 10,
    boxShadow: 'none',
    border: `1px solid ${theme.palette.green.pale}`,
    marginRight: 24,
  },
});
