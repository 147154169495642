import { useEffect, useState } from 'react';
import {
  MeetingSessionConfiguration,
  DefaultMeetingSession,
  ConsoleLogger,
  DefaultDeviceController,
  LogLevel,
  MeetingSessionStatusCode,
} from 'amazon-chime-sdk-js';
import {
  ContactRequestConstants,
  initiateOutboundCall,
  getAllContactRequests,
  endOutBoundCall,
  getMeetingDetails,
  bulkUpdateContactStatus,
} from '../../services';
import { Constant } from '../../../../shared/services/constant/constant';

const useContactsCallingHook = (meetingManager, handlePollingInterval) => {
  const [isCallingModalOpen, setisCallingModalOpen] = useState(false);
  const logger = new ConsoleLogger('thriveChime', LogLevel.ERROR);
  const deviceController = new DefaultDeviceController(logger);
  const [approvedContacts, setApprovedContacts] = useState([]);
  const [privateContacts, setPrivateContacts] = useState([]);
  const [archiveEligibleContacts, setArchiveEligibleContacts] = useState([]);
  const [transactionId, setTransactionId] = useState('');
  const [selectedContact, setSelectedContact] = useState({});
  const [meetingId, setMeetingId] = useState('');
  const [callStatus, setCallStatus] = useState(ContactRequestConstants.CONTACT_CONNECTION_STATUSES.CALLING);
  const [timePerCall, setTimePerCall] = useState(0);
  const [isCallinProgress, setIsCallinProgress] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isDailyMinutesExceeded, setIsDailyMinutesExceeded] = useState(false);
  const [modalStatus, setModalStatus] = useState('');
  const [noAnswerHangupTimeoutId, setNoAnswerHangupTimeoutId] = useState(null);
  const [isCallDeclined, setIsCallDeclined] = useState(false);
  const [consumedContactQuota, setConsumedContactQuota] = useState(0);
  const [maxContactQuota, setMaxContactQuota] = useState(0);
  const audio = new Audio(`${Constant.CDN_BASE_URL}/assets/audios/voice-call/ringing-sound.mp3`);
  audio.id = 'ringingSound';

  useEffect(() => {
    getContactRequests();
    stopRinging();
    return () => clearTimeout(noAnswerHangupTimeoutId);
  }, []);

  useEffect(() => {
    if (callStatus === ContactRequestConstants.CONTACT_CONNECTION_STATUSES.CONNECTED) {
      clearTimeout(noAnswerHangupTimeoutId);
      setNoAnswerHangupTimeoutId(null);
      setIsCallinProgress(true);
    } else {
      setIsCallinProgress(false);
    }
  }, [callStatus]);

  useEffect(() => {
    if (noAnswerHangupTimeoutId && isCallDeclined) {
      clearTimeout(noAnswerHangupTimeoutId);
    }
  }, [noAnswerHangupTimeoutId, isCallDeclined]);

  const getContactRequests = async () => {
    const response = await getAllContactRequests();
    const contacts = await getApprovedContacts(response);
    const privilegedContacts = contacts.filter((contact) =>
      Object.values(ContactRequestConstants.PRIVATE_CONTACTS_RELATIONSHIP_TYPES).includes(contact.relationshipType)
    );
    setApprovedContacts((prevContacts) => {
      return contacts.filter((contact) => !privilegedContacts.includes(contact));
    });
    setPrivateContacts(privilegedContacts);
    const { maxContactQuota, consumedContactQuota } = response.data;
    if (maxContactQuota - consumedContactQuota < 0) {
      const archiveEligibleContacts = getArchiveEligibleContacts(response);
      setMaxContactQuota(maxContactQuota);
      setConsumedContactQuota(consumedContactQuota);
      setArchiveEligibleContacts(archiveEligibleContacts);
    } else {
      setArchiveEligibleContacts([]);
    }
  };
  const handleErroModalOpen = () => {
    setIsErrorModalOpen(true);
  };
  const playRinging = () => {
    audio.play();
    audio.loop = true;
  };

  // Function to stop the ringing sound
  const stopRinging = () => {
    audio.currentTime = 0;
    audio.pause();
  };

  const handleErroModalClose = () => {
    setIsErrorModalOpen(false);
  };
  const onCallClick = async (contact) => {
    try {
      const response = await initiateOutboundCall(contact.phoneNumber);
      if (response?.data?.outboundCallResponse) {
        setTransactionId(response?.data?.outboundCallResponse?.transactionId);
        setMeetingId(response?.data?.meetingResponse?.meetingId);
        setTimePerCall(response?.data?.maxCallDurationInMinutes);
        if (contact.name) {
          contact.firstName = contact.name;
          contact.lastName = '';
        }
        setSelectedContact(contact);
        const meetingSessionConfiguration = new MeetingSessionConfiguration(
          response.data.meetingResponse,
          response.data.appAttendeeResponse
        );
        var callback = function (presentAttendeeId, present) {
          console.log('Attendee ID: ' + presentAttendeeId + ' Present: ' + present);
          if (response?.data?.pstnAttendeeResponse?.attendeeId === presentAttendeeId && present) {
            stopRinging();
            setCallStatus(ContactRequestConstants.CONTACT_CONNECTION_STATUSES.CONNECTED);
          }
        };
        const observer = {
          audioVideoDidStart: () => {
            console.log('Started');
          },
          audioVideoDidStop: (sessionStatus) => {
            stopRinging();
            if (callStatus === ContactRequestConstants.CONTACT_CONNECTION_STATUSES.CALLING) {
              setIsCallDeclined(true);
            }

            console.log('Stopped with a session status code: ', sessionStatus.statusCode());
            if (sessionStatus.statusCode() === MeetingSessionStatusCode.MeetingEnded) {
              checkCallEndingStatus(response?.data?.meetingResponse?.meetingId);
            }
            handlePollingInterval('Voice_Calls_Schedule', true); //if there is a call in progress, stop the polling
            setisCallingModalOpen(false);
          },
          audioVideoDidStartConnecting: (reconnecting) => {
            if (reconnecting) {
              // e.g. the WiFi connection is dropped.
              console.log('Attempting to reconnect');
            }
          },
        };
        await meetingManager.join(meetingSessionConfiguration);

        await meetingManager.start();
        const isObserverAdded = meetingManager.meetingSession.audioVideo.addObserver(observer);
        const isAttendeeCallbackAdded =
          meetingManager.meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(callback);

        setisCallingModalOpen(true);
        noAnswerHangupTimeout(response, meetingManager);
        playRinging();

        handlePollingInterval('Voice_Calls_Schedule', false); //if there is a call in progress, stop the polling
      } else if (
        response?.data?.statusCode === ContactRequestConstants.CONTACT_CALLING_ERROR_STATUS_CODES.DAILY_MINUTES_EXCEEDED
      ) {
        setIsErrorModalOpen(true);
        setModalStatus(ContactRequestConstants.CONTACT_CONNECTION_STATUSES.FAILED);
        setIsDailyMinutesExceeded(true);
      }
    } catch (error) {
      console.log('Error in making call', error);
      setIsErrorModalOpen(true);
      setModalStatus(ContactRequestConstants.CONTACT_CONNECTION_STATUSES.FAILED);
    }
  };
  const getApprovedContacts = async (response) => {
    return response.data.content.filter(
      (contact) => contact.status.toLowerCase() === ContactRequestConstants.CONTACT_REQUEST_STATUSES.APPROVED
    );
  };
  const noAnswerHangupTimeout = (response, meetingManager) => {
    const timoutId = setTimeout(() => {
      if (callStatus !== ContactRequestConstants.CONTACT_CONNECTION_STATUSES.CONNECTED) {
        stopRinging();
        endOutBoundCall(response?.data?.outboundCallResponse?.transactionId, response?.data?.meetingResponse?.meetingId);
        meetingManager.leave();
        setisCallingModalOpen(false);
      }
    }, Constant.VOICE_CALL_HANGUP_TIMEOUT);
    setIsCallDeclined(false);
    setNoAnswerHangupTimeoutId(timoutId);
  };
  const checkCallEndingStatus = async (meetingId) => {
    const response = await getMeetingDetails(meetingId);
    if (response?.data?.callState === ContactRequestConstants.CONTACT_CONNECTION_STATUSES.TERMINATED) {
      setIsErrorModalOpen(true);
      setModalStatus(ContactRequestConstants.CONTACT_CONNECTION_STATUSES.TERMINATED);
    }
  };

  const getArchiveEligibleContacts = (response) => {
    const privateRelationshipTypes = ContactRequestConstants.PRIVATE_CONTACTS_RELATIONSHIP_TYPES;
    const archiveExcludedRelationshipTypes = {
      ...privateRelationshipTypes,
      PROBATION_PAROLE_OFFICER: 'PROBATION_PAROLE_OFFICER',
    };
    return response.data.content.filter((contact) => {
      const { status, relationshipType } = contact;
      const statusIsEligible = [
        ContactRequestConstants.CONTACT_REQUEST_STATUSES.APPROVED,
        ContactRequestConstants.CONTACT_REQUEST_STATUSES.PENDING,
      ].includes(status.toLowerCase());
      const relationTypeIsNotPrivileged = !archiveExcludedRelationshipTypes.hasOwnProperty(relationshipType);

      return statusIsEligible && relationTypeIsNotPrivileged;
    });
  };

  const archiveContacts = async (contactIds) => {
    await bulkUpdateContactStatus(contactIds, ContactRequestConstants.CONTACT_REQUEST_STATUSES.INACTIVE.toUpperCase());
    getContactRequests();
  };
  return {
    onCallClick,
    isCallingModalOpen,
    approvedContacts,
    privateContacts,
    selectedContact,
    transactionId,
    meetingId,
    callStatus,
    setCallStatus,
    isCallinProgress,
    timePerCall,
    setisCallingModalOpen,
    isErrorModalOpen,
    modalStatus,
    isDailyMinutesExceeded,
    handleErroModalClose,
    handleErroModalOpen,
    maxContactQuota,
    consumedContactQuota,
    archiveEligibleContacts,
    archiveContacts,
  };
};

export default useContactsCallingHook;
