export default (theme) => ({
  root: {
    '&$progCard': {
      display: 'flex',
      cursor: 'pointer',
      boxShadow: '0px 4px 8px 2px rgba(142, 142, 142, 0.1)',
      backgroundColor: theme.palette.primary.white,
      position: 'relative',
      minHeight: '225px',
      borderRadius: 5,
      textDecoration: 'none',
      width: '100%',
      padding: 0,
    },
    '&$progCard:hover': {
      backgroundColor: theme.palette.primary.white,
    },
  },
  progCard: {
    // need this for the class to show up
  },
  thumbnailContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    minHeight: 'inherit',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardTitle: {
    fontFamily: theme.typography.secondaryFont,
    ...theme.typography.t24,
    '-webkit-line-clamp': 2,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
    wordBreak: 'break-word',
    fontWeight: 700,
    color: theme.palette.primary.dark,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 0%',
    margin: '20px 20px 10px',
    padding: 0,
    fontFamily: theme.typography.secondaryFont,
  },
  providerLogoContainer: {
    zIndex: 99,
    position: 'absolute',
    bottom: 0,
    left: '15px',
    borderRadius: '5px 5px 0 0',
    background: theme.palette.primary.white,
    padding: 10,
    maxWidth: '85%',
  },
  providerLogo: {
    maxHeight: 40,
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: '5px 0 0 5px',
  },
  actionBtn: {
    borderRadius: 100,
    padding: '12px 20px',
    '&$viewProgBtn': {
      ...theme.typography.t18,
      ...theme.typography.bold,
      lineHeight: '16px',
      letterSpacing: '1px',
      textTransform: 'capitalize',
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.pale,
    },
  },
  viewProgBtn: {
    // need this for the class to show up
  },
  actionBox: {
    marginLeft: '20px',
    marginBottom: 5,
    width: 'fit-content',
  },
  description: {
    fontSize: '1rem',
    '-webkit-line-clamp': 4,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
    workBreak: 'break-all',
  },
  banner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '-20px',
    right: '-20px',
    padding: '0.5rem',
    width: '200px',
    background: theme.palette.primary.main,
    color: 'white',
    textAlign: 'center',
    boxShadow: '4px 4px 15px rgba(26, 35, 126, 0.2)',
  },
  bannerText: {
    marginLeft: '10px',
    fontWeight: 700,
  },
});
