import { MessagingService } from '../../../../content';

export const redirectToUrl = (url) => {
  window.location.href = url;
};

export const isPublicRoute = (routes) => {
  let currentRoute = window.location.pathname;

  // update currentRoute for language routes
  const isLanguageRoute = /\/login\/change-language\/([\w])+$/.test(currentRoute);
  if (isLanguageRoute) currentRoute = '/login/change-language/:locale';

  return routes.getRoutes('public').some((route) => route.path === currentRoute);
};

export const emitMessageEvent = (data) => {
  const event = new CustomEvent(MessagingService.NEW_MSG_EVENT, { detail: data });
  const messageElement = document.querySelector(`#${MessagingService.MESSAGE_ELEMENT}`);
  if (messageElement) {
    messageElement.dispatchEvent(event);
  }
};
