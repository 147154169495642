import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import RestoreIcon from '@material-ui/icons/Restore';
import { FolderNav } from '@orijinworks/frontend-commons';

import useVoiceCallHook from './useVoiceCallHook';
import styles from './voice-call.style';
import { ContactsCalling, ContactsRequests } from './../../components';
import withMeetingProvider from '../../../../core/components/MeetingProvider/withMeetingProvider';
import { useDocumentTitleHook } from '../../../../v2/shared';
import CallCenterScheduling from '../../components/call-center-scheduling/call-center-scheduling';
import useScheduleWrapperHook from '../../../../shared/components/schedule-wrapper/useScheduleWrapperHook';
import { Constant } from '../../../../shared';
import GroupScheduleBanner from '../../components/group-schedule-banner/group-schedule-banner';
import { store } from '../../../../core/store';
import useUnleashHook from '../../../../core/components/unleash/useUnleashHook';
import { UnleashService } from '../../../../core/services/unleash/unleash';
import CallUsageBanner from '../../components/call-history/components/call-usage-banner/call-usage-banner';
import useCallUsageBannerHook from '../../components/call-history/components/call-usage-banner/useCallUsageBannerHook';
import { useEffect } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Permission } from '../../../../core';
import CallHistory from '../../components/call-history/container/call-history/call-history';

const VoiceCall = (props) => {
  const { classes } = props;
  const { tabActive, TAB_VALUES, handletabActive, approvedContacts } = useVoiceCallHook(props);
  const { app } = store.getState();

  // Hooks
  const { isLoading, isClosed, handlePollingInterval, schedule, groupSchedule, isGroupScheduleClosed } =
    useScheduleWrapperHook(Constant.FEATURES_ENUM.VOICE_CALLS_SCHEDULE);
  const {
    maxCallDurationPerCall,
    maxCallDurationPerDay,
    consumedCallDurationPerDay,
    remainingCallDurationPerDay,
    bannerType,
    isLoading: isBannerLoading,
    updateCallUsages,
  } = useCallUsageBannerHook();

  const { isFlagEnabled: isVoiceGroupScheduleEnabled } = useUnleashHook(
    UnleashService.FLAGS.VOICE_GROUP_SCHEDULE,
    app.userDetail.userName,
    app.userDetail.userId
  );

  useDocumentTitleHook('page.title.voice-calls');

  useEffect(() => {
    if (tabActive === TAB_VALUES.CallHistory) {
      updateCallUsages();
    }
    window.scrollTo(0, 0);
  }, [tabActive]);

  // Helper functions
  const renderTabContent = (tabActive) => {
    switch (tabActive) {
      case TAB_VALUES.Contacts:
        return (
          <>
            <ContactsCalling
              approvedContacts={approvedContacts}
              isClosed={
                isVoiceGroupScheduleEnabled() ? bannerType === 'CALL_TIME_LIMIT_REACHED' || isGroupScheduleClosed : isClosed
              }
              handletabActive={handletabActive}
              TAB_VALUES={TAB_VALUES}
              handlePollingInterval={handlePollingInterval}
            />
          </>
        );
      case TAB_VALUES.Connections:
        return (
          <>
            <ContactsRequests />
          </>
        );
      case TAB_VALUES.CallHistory:
        return (
          <>
            {groupSchedule && groupSchedule.length > 0 && (
              <CallUsageBanner
                maxCallDurationPerCall={maxCallDurationPerCall}
                maxCallDurationPerDay={maxCallDurationPerDay}
                consumedCallDurationPerDay={consumedCallDurationPerDay}
                remainingCallDurationPerDay={remainingCallDurationPerDay}
                isLoading={isBannerLoading}
                bannerType={bannerType}
                tabActive={tabActive}
              />
            )}
            <CallHistory />
          </>
        );
      default:
        return null;
    }
  };

  const renderVoiceGroupSchedule = (tabActive, bannerType) => {
    if (tabActive === TAB_VALUES.CallHistory || tabActive === TAB_VALUES.Connections) return null;

    const isGroupSchedule = isVoiceGroupScheduleEnabled();
    const currentSchedule = isGroupSchedule ? groupSchedule : schedule;
    const currentIsClosed = isGroupSchedule ? isGroupScheduleClosed : isClosed;

    if (isLoading || currentSchedule.length === 0) return null;

    return (
      <Grid item sm={12} key={isGroupSchedule ? 'groupSchedule' : 'schedule'}>
        <CallCenterScheduling
          schedule={currentSchedule}
          isClosed={currentIsClosed}
          isCallTimeLimitReached={bannerType === 'CALL_TIME_LIMIT_REACHED'}
        />
      </Grid>
    );
  };

  const renderGroupScheduleBanner = () => {
    return <GroupScheduleBanner />;
  };
  const renderSkeletons = () => {
    return Array(5)
      .fill()
      .map((_, index) => (
        <Skeleton
          key={index}
          variant="rounded"
          animation="wave"
          width={'100%'}
          height={100}
          style={{ marginBottom: '16px' }}
        />
      ));
  };

  const renderTabs = () => {
    return [
      { label: TAB_VALUES.Contacts, value: TAB_VALUES.Contacts, component: () => null, Icon: PhoneInTalkIcon },
      { label: TAB_VALUES.CallHistory, value: TAB_VALUES.CallHistory, component: () => null, Icon: RestoreIcon },
      { label: TAB_VALUES.Connections, value: TAB_VALUES.Connections, component: () => null, Icon: PersonAddIcon },
    ];
  };

  // Render
  return (
    <Permission {...props}>
      <Box display="flex" flexDirection="column" className={classes.tabContainer}>
        <FolderNav
          defaultActiveTab={tabActive}
          onTabChange={handletabActive}
          color="var(--blue-main)"
          tabFontColor="var(--blue-main)"
          tabs={renderTabs()}
        >
          <Box className={classes.tabBody}>
            {isBannerLoading || isLoading ? (
              renderSkeletons()
            ) : (
              <>
                {(!groupSchedule || groupSchedule?.length <= 0) &&
                tabActive !== TAB_VALUES.Connections &&
                isVoiceGroupScheduleEnabled()
                  ? renderGroupScheduleBanner()
                  : renderVoiceGroupSchedule(tabActive, bannerType)}
                {renderTabContent(tabActive)}
              </>
            )}
          </Box>
        </FolderNav>
      </Box>
    </Permission>
  );
};

export default withMeetingProvider(withStyles(styles)(VoiceCall));
