import React, { useEffect, createContext } from 'react';
import { useUnleashContext } from '@unleash/proxy-client-react';
import { connect } from 'react-redux';
import { fetchDeviceGroup, fetchUserIp } from '../../../v2/core/auth/utils';

const UserProvider = ({ children, userName, jurisdictionCode, userId = 0 }) => {
  const UserContext = createContext();
  const updateContext = useUnleashContext();

  useEffect(() => {
    const setUnleashContext = async () => {
      try {
        if (userName) {
          const promises = [fetchUserIp(), fetchDeviceGroup()];
          const [ipAddress, deviceGroup] = await Promise.all(promises);
          await updateContext({
            ipAddress,
            userId: parseInt(userId),
            userName,
            jurisdictionCode,
            deviceGroupName: deviceGroup?.name,
            deviceGroupPath: deviceGroup?.path,
          });
        }
      } catch (err) {
        console.error(err);
      }
    };

    setUnleashContext();
  }, [userName]);

  return <UserContext.Provider>{children}</UserContext.Provider>;
};

const mapStateToProps = ({ app }) => ({ ...app.userDetail });

export default connect(mapStateToProps)(UserProvider);
