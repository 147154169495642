import { Http, store, toggleToast } from '../../../core';

class ProgramConstants {
  /**
   * @name ENROLLMENT_STATUS
   * @desc ENUM for different program enrollment statuses.
   * @return {Object}
   */
  static get ENROLLMENT_STATUS() {
    return {
      ENROLLED: 'ENROLLED',
      PENDING: 'PENDING',
      UNENROLLED: 'UNENROLLED',
    };
  }

  /**
   * @name TEMPLATE_TYPES
   * @desc ENUM for different program template types.
   * @return {Object}
   */
  static get TEMPLATE_TYPES() {
    return {
      TEMPLATE_SINGLE_COURSE: 'TEMPLATE_SINGLE_COURSE',
      TEMPLATE_MULTIPLE_COURSES_UNIFIED: 'TEMPLATE_MULTIPLE_COURSES_UNIFIED',
      TEMPLATE_MULTIPLE_COURSES_INDIVIDUAL: 'TEMPLATE_MULTIPLE_COURSES_INDIVIDUAL',
    };
  }

  /**
   * @name LEARNING_MODULE_PROGRESS
   * @desc ENUM for different learning module progress.
   * @returns {Object}
   */
  static get LEARNING_MODULE_PROGRESS() {
    return {
      NOT_STARTED: 'NOT STARTED',
      IN_PROGRESS: 'IN PROGRESS',
      COMPLETED: 'COMPLETED',
    };
  }
}

/**
 * @name getProgramDetails
 * @desc retrieve program details
 * @param {String} programId
 * @returns {Promise}
 */
const getProgramDetails = (programId) => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.get(`/programs/v2/${programId}/details`).then((_successLog) => {
      resolve(_successLog);
    });
  });
};

/**
 * @name enrollIntoProgram
 * @desc enroll into program
 * @param {String} programId
 * @returns {Promise}
 */
const enrollIntoProgram = (programId) => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.post(`/programs/${programId}/enroll`).then((_successLog) => {
      resolve(_successLog);
    });
  });
};

/**
 * @name unenrollFromProgram
 * @desc unenroll from program
 * @param {String} programId
 * @returns {Promise}
 */
const unenrollFromProgram = (programId) => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.post(`/programs/${programId}/unenroll`).then((_successLog) => {
      resolve(_successLog);
    });
  });
};

/**
 * @name isEnrolled
 * @desc check if user is enrolled in program
 * @param {String} status
 * @returns {Boolean}
 */
const isEnrolled = (status) => {
  return status === ProgramConstants.ENROLLMENT_STATUS.ENROLLED;
};

/**
 * @name showDefaultErrorToast
 * @desc show default error toast
 */
const showDefaultErrorToast = () => {
  store.dispatch(
    toggleToast({
      variant: 'error',
      message: 'error.msg.default',
      title: 'sorry',
      isOpen: true,
      showCancelButton: true,
    })
  );
};

/**
 * @name showSuccessToast
 * @desc show success toast
 * @param {String} message
 * @param {String} values
 */
const showSuccessToast = (message, values) => {
  store.dispatch(
    toggleToast({
      variant: 'success',
      message,
      messageValues: values,
      title: 'success',
      isOpen: true,
      showCancelButton: true,
    })
  );
};

export {
  ProgramConstants,
  getProgramDetails,
  enrollIntoProgram,
  unenrollFromProgram,
  isEnrolled,
  showDefaultErrorToast,
  showSuccessToast,
};
