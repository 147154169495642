import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button, Radio, FormControlLabel, Collapse } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import styles from './program-selection.styles';
import { Constant } from '../../../../../shared';
import useProgramSelectionHook from './useProgramSelectionHook';

const ProgramSelection = ({ classes, selectedLocale }) => {
  const { selectedProgram, handleProgramSelect, stepTitle, stepDescription, handleEnrollAndNavigate, programs } =
    useProgramSelectionHook(selectedLocale);

  return (
    <Box className={classes.root}>
      <img src={`${Constant.CDN_BASE_URL}/assets/career-compass/program_one.png`} alt="" className={classes.image} />
      <Typography variant="h2" className={classes.title}>
        {stepTitle}
      </Typography>
      <Typography variant="body1" className={classes.subtitle}>
        {stepDescription}
      </Typography>
      <Box className={classes.programList}>
        {programs.map((program) => (
          <Box
            key={program.id}
            className={`${classes.programOption} ${selectedProgram === program.id ? classes.selected : ''}`}
          >
            <FormControlLabel
              control={
                <Radio
                  checked={selectedProgram === program.id}
                  onChange={() => handleProgramSelect(program.id)}
                  value={program.id}
                  name="program-selection"
                  color="primary"
                />
              }
              label={
                <Typography variant="h3" className={classes.programTitle}>
                  {program.title}
                </Typography>
              }
              className={classes.programHeader}
            />
            <Collapse in={selectedProgram === program.id}>
              <Box className={classes.programContent}>
                <Typography variant="body2" className={classes.programDescription}>
                  {program.description}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleEnrollAndNavigate(program.id)}
                  className={classes.selectButton}
                >
                  <FormattedMessage id="career-compass.program-selection.select-button" />
                </Button>
              </Box>
            </Collapse>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

ProgramSelection.propTypes = {
  classes: PropTypes.object.isRequired,
  programs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedLocale: PropTypes.string.isRequired,
};

export default withStyles(styles)(ProgramSelection);
