import { createMuiTheme } from '@material-ui/core/styles';

export class theme {
  constructor() {
    const theme = createMuiTheme({
      palette: {
        colors: {
          grey: '#7896A2',
          borderGrey: {
            light: '#EFF0F4',
            dark: '#A5B4BB',
          },
          textGrey: 'var(--grey-110)',
          inputBackground: '#F3F6F9',
          paleBlue: '#EDF7FC',
          babyBlue: '#E4EFF5',
          lightGray: '#F6F8FA',
          lightBlueGrey: '#E2E9EF',
          paleYellow: '#FFF7E3',
          primaryLight: '#ECF5F5',
          paleBlueGray: '#E6ECEE',
          cornflowerBlue: '#609CD8',
          aliceBlue: '#F2FDFF',
          orijinPurple: '#624CA5',
        },
        CallingModal: {
          btnBgColor: {
            backgroundColor: '#051176',
          },
          CallEndIconColor: {
            backgroundColor: '#F00B0B',
          },
        },
        jobZone: {
          colors: {
            zone1: '#7896A2',
            zone2: '#0F445A',
            zone3: '#1C79BE',
            zone4: '#00656C',
            zone5: '#E3A634',
          },
        },
        pdq: {
          level1: {
            main: '#1C79BE',
            dark: '#0F445A',
          },
          level2: {
            main: '#3B928C',
            dark: '#2C5754',
          },
          level3: {
            main: '#D3682F',
            dark: '#D3682F',
          },
        },
        text: {
          primary: '#0F445A',
          secondary: 'var(--grey-110)',
          sub: 'var(--grey-110)',
        },
        primary: {
          peach: '#FFF7E8',
          pastelBlue: '#a1c1ff',
          pastelBlueBg: 'rgba(65, 113, 210, 0.55)',
          battleshipGrey: '#737982',
          lipstick: '#c92563',
          scarlet: '#b00020',
          textGrey: '#4B4B4B',
          lightGrey: '#8E8E8E',
          black: '#1F1F1F',
          grey: '#d8d8d8',
          main: '#3B928C',
          sub: 'var(--grey-110)',
          light: '#DFE6E9',
          active: '#E3A634',
          darkBlue: '#021843',
          jetBlack: '#000000',
          green: '#66bb6a',
          white: '#ffffff',
          success: '#0f5100',
          themeGreen: '#3ED79E',
          danger: '#840000',
          lightBlue: '#4171d2',
          inverseGradient: 'linear-gradient(to bottom left,#5d6dda 50%, #4190EC)',
          textColor: 'white',
          background: '#F7F8FA',
          biscay: '#173f62',
          grayDark: '#919191',
          hippieBlue: '#5686b0',
          mediumBlue: '#0096CA',
          alertRed: '#FF0000',
          red: '#FD2F13',
          lightOrange: '#FFA500',
          lightYellow: '#FFFF00',
          darkRed: '#BF4636',
          pale: '#F0F6FF',
          greyed: '#C9D2DF',
        },
        voice: {
          dismissBtn: {
            backgroundColor: '#0F48B8',
          },
        },
        blue: {
          main: '#1C79BE',
          pale: '#F0F6FF',
          greyed: '#C9D2DF',
          primary: '#0F48B8',
        },
        green: {
          main: '#2BA369',
          deep: '#1A5E3D',
          pale: '#EAF2F4',
        },
        orange: {
          main: '#D3682F',
          dark: '#B1562F',
        },
        yellow: {
          main: '#E3A634',
          dark: '#BD9400',
          deep: '#5E4A00',
          pale: '#FFF7E3',
        },
        aqua: {
          extraDeep: '#00656C',
          deep: '#3B928C',
          pale: '#F6FDFF',
          extraDeep: '#00656C',
        },
        navy: {
          main: '#061E44',
        },
        cyan: {
          dark: '#00656C',
        },
        cerulean: {
          main: '#0096ca',
          cerulean20: '#0078A2',
        },
        greyScale: {
          grey01: '#757F8B',
          grey02: '#8E9EA0',
          grey10: '#F8F9FC',
          grey03: 'var(--grey-10)',
          grey70: '#78889C',
          grey90: '#707070',
          lavenderGrey: '#BBC5D5',
          charcoal: '#333639',
          110: '#333333',
          100: '#8E9EA0',
          90: '#707070',
          70: '#78889C',
          60: '#CDD4E3',
          20: '#F2F2F2',
          50: '#DBE0E8',
          30: '#EFF0F4',
        },
        boxShadow: '0px 4px 8px 2px rgba(142, 142, 142, 0.1)',
        contentAreas: {
          work: {
            borderColor: '#FACAC2',
            color: '#F14B32',
            backgroundColor: '#FDF4F2',
          },
          education: {
            borderColor: '#B9CAD9',
            color: '#396993',
            backgroundColor: '#E5F1FF',
          },
          rehab: {
            borderColor: '#B2DACF',
            color: '#EBF7F3',
            backgroundColor: '#017E5B',
          },
          reEntry: {
            borderColor: '#8743DF',
            color: '#D4BDF3',
            backgroundColor: '#F4EEFC',
          },
          enrichment: {
            borderColor: '#D2D4D7',
            color: '#696E77',
            backgroundColor: '#F4F5F6',
          },
          entertainment: {
            borderColor: '#F2C9D9',
            color: '#CB2061',
            backgroundColor: '#FCF3F6',
          },
        },
        secondary: {
          main: '#0F445A',
          light: '#CBDDDF',
        },
        gradient: {
          'occ-gradient': 'linear-gradient(#C4F2FF 0%, #86E6FF 100%)',
        },
      },
      status: {
        danger: '#F2AE00',
      },
      alert: {
        error: '#BE311E',
      },
      MuiListItemText: {
        primary: 'white',
      },
      typography: {
        useNextVariants: true,
        fontSize: 14,
        fontFamily: 'DM Sans',
        h1: {
          fontSize: 32,
          fontWeight: 800,
        },
        h2: {
          fontSize: 30,
          fontWeight: 800,
        },
        h3: {
          fontSize: 24,
          fontWeight: 700,
        },
        h4: {
          fontSize: 22,
          fontWeight: 700,
        },
        bolder: {
          fontWeight: 900,
        },
        bold: {
          fontWeight: 700,
        },
        light: {
          fontWeight: 300,
        },
        medium: {
          fontWeight: 600,
        },
        regular: {
          fontWeight: 400,
        },
        t60: {
          fontSize: 60,
        },
        t44: {
          fontSize: 44,
        },
        t47: {
          fontSize: 47,
        },
        t46: {
          fontSize: 46,
        },
        t36: {
          fontSize: 36,
        },
        t30: {
          fontSize: 30,
        },
        t20: {
          fontSize: 20,
        },
        t22: {
          fontSize: 22,
        },
        t35: {
          fontSize: 35,
        },
        t48: {
          fontSize: 48,
        },
        t14: {
          fontSize: 14,
        },
        t16: {
          fontSize: 16,
        },
        t34: {
          fontSize: 34,
        },
        t25: {
          fontSize: 25,
        },
        t24: {
          fontSize: 24,
        },
        t32: {
          fontSize: 32,
        },
        t18: {
          fontSize: 18,
        },
        t12: {
          fontSize: 12,
        },
        t10: {
          fontSize: 10,
        },
        t9: {
          fontSize: 9,
        },
        t7: {
          fontSize: 7,
        },
      },
    });

    theme.shadows[1] = '0px 4px 8px 2px rgba(142, 142, 142, 0.1)';
    return theme;
  }
}
