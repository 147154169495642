import {
  SET_CURRENT_USER,
  LOADER,
  SET_LOCALE,
  SET_BALANCE,
  SET_TRANSACTIONS,
  SET_FEATURES,
  SHOW_MODAL,
  SET_CONTENT_AREAS,
  TOGGLE_TOAST,
  SET_FORMS_ENABLED,
  SET_UNREAD_MESSAGES_COUNT,
  SET_UNREAD_ANNOUNCEMENT_COUNT,
  SET_REQUIRED_FORM_EXISTANCE,
  SET_REQUIRED_FORM_EXIST_ON_LOGIN,
  SET_LEARNER_SCHEDULE,
  SET_FRIEND_FAMILY_UNREAD_COUNT,
  SET_NEW_THEME,
  SHOW_SESSION_EXPIRE_PAGE,
  SET_APP_LEVEL_API_LOADING,
  SET_IP_ADDRESS,
  SET_DEVICE_GROUP,
} from './constants';

const initialState = {
  isAppLevelApiLoading: true,
  userDetail: {},
  locale: 'en',
  isLoading: false,
  isOpen: false,
  title: '',
  message: '',
  onConfirm: null,
  balance: 0,
  transactions: null,
  features: [],
  featureLoading: true,
  toast: {
    variant: null,
    message: null,
    isOpen: false,
    showCancelButton: false,
  },
  contentAreas: {
    isLoading: false,
    collection: [],
  },
  learnerSchedule: [],
  isFormsEnabled: null,
  isRequiredFormExist: null,
  isRequiredFormExistOnLogin: null,
  friendsAndFamily: {
    unreadData: {
      relationship: [],
    },
    eTag: 0,
  },
  useNewTheme: false,
  openSessionExpire: false,
  ipAddress: null,
  deviceGroup: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return Object.assign({}, state, {
        userDetail: { ...state.userDetail, ...action.currentUser },
      });
    case LOADER:
      return Object.assign({}, state, {
        isLoading: action.loaderState.isLoading,
      });
    case TOGGLE_TOAST:
      return Object.assign({}, state, { toast: action.toast });
    case SET_LOCALE:
      return Object.assign({}, state, action.locale);
    case SHOW_MODAL:
      return Object.assign({}, state, action.modalMetaInfo);
    case SET_FEATURES:
      return Object.assign({}, state, {
        features: action.features,
        featureLoading: false,
      });
    case SET_BALANCE:
      return Object.assign({}, state, action.balance);

    case SET_TRANSACTIONS:
      return Object.assign({}, state, { transactions: action.transactions });

    case SET_CONTENT_AREAS:
      return Object.assign({}, state, {
        contentAreas: {
          isLoading: false,
          collection: action.collection,
        },
      });
    case SET_FORMS_ENABLED:
      return Object.assign({}, state, {
        isFormsEnabled: action.isFormsEnabled,
      });
    case SET_UNREAD_MESSAGES_COUNT:
      return Object.assign({}, state, { unreadMessagesCount: action.counts });
    case SET_UNREAD_ANNOUNCEMENT_COUNT:
      return Object.assign({}, state, {
        announcementCount: action.announcementCount,
      });
    case SET_REQUIRED_FORM_EXISTANCE:
      return Object.assign({}, state, {
        isRequiredFormExist: action.isRequiredFormExist,
      });
    case SET_REQUIRED_FORM_EXIST_ON_LOGIN:
      return Object.assign({}, state, {
        isRequiredFormExistOnLogin: action.isRequiredFormExistOnLogin,
      });
    case SET_LEARNER_SCHEDULE:
      return {
        ...state,
        learnerSchedule: action.schedule,
      };
    case SET_FRIEND_FAMILY_UNREAD_COUNT:
      return {
        ...state,
        friendsAndFamily: {
          unreadData: action.unreadData,
          eTag: Math.random(),
        },
      };
    case SET_NEW_THEME:
      return {
        ...state,
        useNewTheme: action.useNewTheme,
      };

    case SHOW_SESSION_EXPIRE_PAGE:
      return Object.assign({}, state, action.openSessionExpire);

    case SET_APP_LEVEL_API_LOADING:
      return Object.assign({}, state, {
        isAppLevelApiLoading: action.isAppLevelApiLoading,
      });

    case SET_IP_ADDRESS:
      return Object.assign({}, state, {
        ipAddress: action.ipAddress,
      });

    case SET_DEVICE_GROUP:
      return Object.assign({}, state, {
        deviceGroup: action.deviceGroup,
      });

    default:
      return state;
  }
}
