import { Utility } from '../../../shared/services/utility/utility';

export class UnleashService {
  static CONFIG = {
    url: Utility.getEnv('REACT_APP_UNLEASH_URL'),
    clientKey: Utility.getEnv('REACT_APP_UNLEASH_CLIENT_KEY'),
    refreshInterval: Utility.getEnv('REACT_APP_UNLEASH_REFRESH_INTERVAL'),
    appName: Utility.getEnv('REACT_APP_UNLEASH_APP_NAME'),
    environment: Utility.getEnv('REACT_APP_UNLEASH_ENVIRONMENT'),
  };
  static FLAGS = {
    USE_V2_THREADS: 'useV2Threads',
    ALLOW_LINE_BREAKS: 'allowLineBreaks',
    STAFF_MESSAGING_V2: 'staffMessagingV2',
    TRANSCRIPT_V2: 'transcriptV2',
    TOGGLE_MESSAGE_VISIBILITY: 'toggleMessageVisibility',
    REFACTOR_BOOKMARKS_MODULE: 'refactorBookmarksModule',
    LEARNER_PORTAL_REBRANDING: 'learnerPortalRebranding',
    NEW_PROGRAM_CATALOG_DESIGN: 'newProgramCatalogDesign',
    CAREER_VIDEOS_INTEGRATION: 'careerVideosIntegration',
    PROGRAM_CERTIFICATE: 'programCertificate',
    PROGRESS_TRACKING_USING_AWARDS_API: 'progressTrackingAwards',
    CAREER_DIRECTORY_UPDATE: 'careerDirectoryUpdate',
    LEFT_NAV_UPDATE: 'leftNavUpdate',
    ARCHIVE_CONTACTS: 'archiveContacts',
    BREADCRUMB_NAVIGATION: 'breadcrumbNavigation',
    EXPLORE_PAGE_REDESIGN: 'explorePageRedesign',
    FEATURED_CONTENT_V2: 'FeaturedContentV2',
    ENGEN_ACCOUNT_DYNAMIC_PROVISION: 'engenAccountdynamicProvision',
    VOICE_GROUP_SCHEDULE: 'learnerVoiceGroupSchedule',
    EMBED_DESMOS_CALCULATOR: 'embedDesmosCalculator',
    REMAINING_TOKEN_BALANCE: 'remainingTokenBalance',
    USE_OKTA_AUTH0: 'useOktaAuth0',
    ARCHIVE_UNARCHIVE_CONTACTS: 'archiveUnarchiveContacts',
    TRANSLATE_EXPLORE: 'translateExploreModule',
  };
}
