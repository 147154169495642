export default (theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 30,
    borderRadius: 10,
    backgroundColor: 'white',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerTitle: {
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  headerImg: {
    maxWidth: 300,
    maxHeight: 250,
  },
  tabBar: {
    backgroundColor: 'white',
    width: 'fit-content',
    border: '1px solid rgba(0,0,0,0.23)',
  },
  tabIndicator: {
    display: 'none',
  },
  tabSelected: {
    backgroundColor: '#9ACAFF',
  },
  tabs: {
    minHeight: 35,
  },
  tab: {
    minHeight: 35,
  },
  searchBar: {
    backgroundColor: 'white',
    borderRadius: 10,
  },
  programsContainer: {
    margin: '25px 0px',
  },
  topBarContainer: {
    margin: '25px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    marginTop: 50,
  },
  searchBarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchBarWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchBtn: {
    border: '1px solid rgba(0,0,0,0.26)',
    textTransform: 'capitalize',
    marginLeft: 15,
    paddingLeft: 35,
    paddingRight: 35,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alignRightContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  noResult: {
    padding: 20,
  },
});
