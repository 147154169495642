import React from 'react';
import { Box, Button, Card, CardContent, Grid, Typography, useTheme, withStyles } from '@material-ui/core';
import ContactRelationshipIcons from '../contact-relationship-icons/contact-relationship-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './private-contacts-calling.style';
import { Utility } from '../../../../shared/services';
import { DialIcon } from '../../../../shared';

const relationshipTypeLabel = {
  GENERAL_PRIVILEGED_CONTACT: 'GENERAL PRIVILEGED CONTACT',
  ATTORNEY: 'ATTORNEY',
  MEDICAL: 'MEDICAL',
};

const PrivateContactsCalling = (props) => {
  const { classes, isClosed } = props;
  const intl = useIntl();
  const theme = useTheme();
  const dialButtonColor = isClosed ? theme.palette.greyScale.grey90 : theme.palette.primary.main;

  return (
    <Grid container sm={12} spacing={3}>
      <Grid item sm={12}>
        <Box className={classes.mainBox}>
          <Card className={classes.parentCard}>
            <CardContent>
              <Typography id="private-contacts-heading" variant="h2" className={classes.heading}>
                <FormattedMessage id="contactsCalling.privateContacts" />
              </Typography>

              <Typography id="contacts-desc" variant="body1" className={classes.subtext}>
                <FormattedMessage id="contactsCalling.monitoringAndRecordingPrivilegedContact" />
              </Typography>
            </CardContent>

            <CardContent id="private-contacts">
              {props.privateContacts?.map((contact) => (
                <Card key={contact.id}>
                  <CardContent>
                    <Grid container spacing={2} alignItems="center" className={classes.submitedContacts}>
                      <Grid item>
                        {contact?.relationshipType && (
                          <ContactRelationshipIcons relationshipType={contact.relationshipType} />
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <Typography id="name" variant="h6" className={classes.name}>
                          {Utility.getFullName(contact.firstName, contact.lastName)}
                        </Typography>
                        <Typography id="relationshipType" variant="body1" className={classes.relationship}>
                          {relationshipTypeLabel[contact.relationshipType]}
                        </Typography>
                        <Typography id="contactNumber" variant="body2" className={classes.contactNumber}>
                          <FormattedMessage id="contact" />: {contact.phoneNumber}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={2}>
                        <Button
                          color="primary"
                          disabled={isClosed}
                          className={classes.btnRadius}
                          onClick={() => {
                            props.onCallClick(contact);
                          }}
                          aria-label={intl.formatMessage({ id: 'contactsCalling.callButtonText' })}
                        >
                          <DialIcon size={42} fill={dialButtonColor} />
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ))}
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(PrivateContactsCalling);
