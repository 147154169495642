import { Avatar, Box, Button, Card, CardContent, Grid, Typography, useTheme, withStyles } from '@material-ui/core';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PrivateContactsCalling from '../private-contacts-calling/private-contacts-calling';
import HotlineContacts from '../hotlines-contacts/hotline-contacts';
import CallingModal from '../calling-modal/calling-modal';
import styles from './contacts-calling.style';
import useContactsCallingHook from './useContactsCallingHook';
import useHotlineContactsHook from '../hotlines-contacts/useHotlineContactsHook';
import { DialIcon, Utility } from '../../../../shared';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import StatusModal from '../status-modal/status-modal';
import { UnleashService } from '../../../../core/services/unleash/unleash';
import ArchiveContactsModal from '../archive-contact-modal/archive-contact-modal';
import { store } from '../../../../core/store';
import useUnleashHook from '../../../../core/components/unleash/useUnleashHook';
const ContactsCalling = (props) => {
  const { classes, handletabActive, TAB_VALUES, isClosed, handlePollingInterval } = props;
  const meetingManager = useMeetingManager();
  const theme = useTheme();
  const dialButtonColor = isClosed ? theme.palette.greyScale.grey90 : theme.palette.primary.main;
  const { app } = store.getState();

  const { isFlagEnabled } = useUnleashHook(
    UnleashService.FLAGS.ARCHIVE_CONTACTS,
    app.userDetail.userName,
    app.userDetail.userId
  );

  const {
    approvedContacts,
    privateContacts,
    isCallingModalOpen,
    selectedContact,
    transactionId,
    meetingId,
    callStatus,
    setCallStatus,
    timePerCall,
    isCallinProgress,
    setisCallingModalOpen,
    onCallClick,
    isErrorModalOpen,
    modalStatus,
    isDailyMinutesExceeded,
    handleErroModalClose,
    handleErroModalOpen,
    maxContactQuota,
    consumedContactQuota,
    archiveContacts,
    archiveEligibleContacts,
  } = useContactsCallingHook(meetingManager, handlePollingInterval);
  const { hotlineContacts } = useHotlineContactsHook({ isClosed });

  const intl = useIntl();
  return (
    <>
      {approvedContacts.length === 0 && (
        <Grid container spacing={3} sm={12} className={classes.noContactsContainer}>
          <Grid item sm={12} className={classes.mainContainer}>
            <Box className={classes.voiceCallImg}>
              <img src="/assets/img/videoCall.png" alt="Contact Requests" id="voiceCallImg" />
            </Box>
          </Grid>

          <Grid item sm={12}>
            <Box className={classes.voiceCallContent}>
              <Typography id="contacts-calling-title" variant="h2" className={classes.title}>
                <FormattedMessage id="contactsCalling.title" />
              </Typography>
              <Typography id="contacts-calling-description" variant="h6" className={classes.content}>
                <FormattedMessage id="contactsCalling.description" />
              </Typography>
            </Box>
          </Grid>

          <Grid item sm={12}>
            <Box className={classes.voiceCallButton}>
              <Button
                id="add"
                variant="contained"
                color="primary"
                aria-label={intl.formatMessage({ id: 'contactsCalling.sendInvite' })}
                onClick={(event) => handletabActive(event, TAB_VALUES.Connections)}
              >
                <FormattedMessage id="contactsCalling.sendInvite" />
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
      {approvedContacts.length > 0 && (
        <Grid container sm={12} spacing={3}>
          <Grid item sm={12}>
            <Box width={'100%'}>
              <Card className={classes.parentCard}>
                <CardContent>
                  <Typography id="approved-contacts" variant="h2" className={classes.heading}>
                    <FormattedMessage id="contactsCalling.approvedContacts" />
                  </Typography>
                  <Typography id="contacts-desc" variant="body1" className={classes.subtext}>
                    <FormattedMessage id="contactsCalling.monitoringAndRecordingApprovedContact" />
                  </Typography>
                </CardContent>
                <CardContent>
                  {approvedContacts?.map((contact, index) => (
                    <Card key={index} className={classes.contactRow}>
                      <CardContent>
                        <Grid container spacing={2} alignItems="center" className={classes.submitedContacts}>
                          <Grid item>
                            <Avatar className={classes.avatar}>{contact.avatar}</Avatar>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant="h6" className={classes.name}>
                              {Utility.getFullName(contact.firstName, contact.lastName)}
                            </Typography>
                            <Typography variant="body1" className={classes.relationship}>
                              {contact.relationshipType.replace(/_/g, ' ')}
                            </Typography>
                            <Typography variant="body2" className={classes.contactNumber}>
                              <FormattedMessage id="contact" />: {contact.phoneNumber}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}></Grid>
                          <Grid item xs={2}>
                            <Button
                              disabled={isClosed}
                              color="primary"
                              className={classes.addContactBtn}
                              onClick={() => {
                                onCallClick(contact);
                              }}
                              aria-label={intl.formatMessage({ id: 'call' })}
                            >
                              <DialIcon size={42} fill={dialButtonColor} />
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  ))}
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      )}

      {hotlineContacts?.length > 0 && (
        <HotlineContacts
          hotlineContacts={hotlineContacts}
          isClosed={isClosed}
          dialButtonColor={dialButtonColor}
          handlePollingInterval={handlePollingInterval}
        />
      )}
      {privateContacts?.length > 0 && (
        <PrivateContactsCalling isClosed={isClosed} privateContacts={privateContacts} onCallClick={onCallClick} />
      )}

      <CallingModal
        isCallingModalOpen={isCallingModalOpen}
        setisCallingModalOpen={setisCallingModalOpen}
        setonClose={() => {}}
        selectedContact={selectedContact}
        transactionId={transactionId}
        meetingId={meetingId}
        callStatus={callStatus}
        setCallStatus={setCallStatus}
        timePerCall={timePerCall}
        isCallinProgress={isCallinProgress}
      />

      <StatusModal
        status={modalStatus}
        description={isDailyMinutesExceeded ? <FormattedMessage id="dailyMinutesLimitExceededError" /> : ''}
        handleStatusModalOpen={handleErroModalOpen}
        handleStatusModalClose={handleErroModalClose}
        isStatusModalOpen={isErrorModalOpen}
      />
      {isFlagEnabled() && archiveEligibleContacts.length > 0 && (
        <ArchiveContactsModal
          open={true}
          onClose={() => {}}
          onArchive={archiveContacts}
          maxContactQuota={maxContactQuota}
          consumedContactQuota={consumedContactQuota}
          contacts={archiveEligibleContacts}
        />
      )}
    </>
  );
};
export default withStyles(styles)(ContactsCalling);
