import { useEffect } from 'react';
import { store, toggleToast } from '../../../../../core';
import { CAREER_COMPASS_CONSTANTS, fetchCurrentStep, updateCurrentStep } from '../../services/career-compass.service';

/**
 * Custom hook for the Career Compass component
 *
 * @returns {object} - The hook methods and data
 */
const useCareerCompassHook = () => {
  const { careerCompass } = store.getState();
  const { currentStep } = careerCompass;

  /**
   * Effect to fetch the current step and programs.
   * It will only fetch the current step if it is not already fetched.
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentStep === null) {
          await fetchCurrentStep();
        }
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    fetchData();
  }, [currentStep]);

  /**
   * Function to check if the current step is the introduction step
   *
   * @returns {boolean} - The current step introduction status
   */
  const isCurrentStepIntroduction = () => {
    return currentStep?.name === CAREER_COMPASS_CONSTANTS.STEPS.INTRODUCTION;
  };

  const isCurrentStepSelection = () => {
    return currentStep?.name === CAREER_COMPASS_CONSTANTS.STEPS.PROGRAM_PROGRESSION;
  };

  /**
   * Function to handle the current step update.
   * It will update the current step with the provided state.
   * It will show a toast message if there is an error.
   *
   * @param {string} state - The state to update
   */
  const handleCurrentStepUpdate = async (state) => {
    try {
      const data = { stepId: currentStep.id, state };
      await updateCurrentStep(data);
      fetchCurrentStep();
    } catch (e) {
      store.dispatch(
        toggleToast({
          variant: 'error',
          message: 'error.msg.default',
          title: 'sorry',
          isOpen: true,
          showCancelButton: true,
        })
      );
    }
  };

  /**
   * Effect to disable the body scroll
   */
  useEffect(() => {
    if (isCurrentStepIntroduction()) document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, [currentStep, isCurrentStepIntroduction]);

  return { isCurrentStepIntroduction, isCurrentStepSelection, handleCurrentStepUpdate };
};

export default useCareerCompassHook;
