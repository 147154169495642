import React from 'react';
import PropTypes from 'prop-types';
import { Box, Icon, Typography, withStyles } from '@material-ui/core';
import styles from './certificate-tile.style';
import { FormattedMessage, useIntl } from 'react-intl';
import { PillButton } from '../../../../../frontend-commons';

const CertificateTile = ({ classes, onViewCertificate }) => {
  const intl = useIntl();

  return (
    <Box className={classes.root}>
      <img
        id="certificate-tile-image"
        className={classes.image}
        alt=""
        src="https://content.connectedcorrections.com/assets/img/Orijin-Celebration.webp"
      />
      <Box className={classes.content}>
        <Box className={classes.textContainer}>
          <Typography id="certificate-tile-title" variant="h4" className={classes.title}>
            <FormattedMessage id="program-certificate.tile.title" />
          </Typography>
          <Typography id="certificate-tile-description" variant="body1" className={classes.description}>
            <FormattedMessage id="program-certificate.tile.description" />
          </Typography>
        </Box>
        <PillButton
          id="certificate-tile-button"
          color="secondary"
          size="large"
          variant="contained"
          className={classes.viewButton}
          startIcon={<Icon>emoji_events</Icon>}
          aria-label={intl.formatMessage({ id: 'program-certificate.tile.button-text' })}
          onClick={onViewCertificate}
        >
          <FormattedMessage id="program-certificate.tile.button-text" />
        </PillButton>
      </Box>
    </Box>
  );
};

CertificateTile.propTypes = {
  classes: PropTypes.object.isRequired,
  onViewCertificate: PropTypes.func.isRequired,
};

export default withStyles(styles)(CertificateTile);
