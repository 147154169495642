export default (theme) => ({
  mainBox: {
    width: '100%',
  },
  parentCard: {
    margin: theme.spacing(2),
  },
  heading: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  subtext: {
    marginTop: '1%',
    fontSize: '1rem',
    whiteSpace: 'pre-line',
  },
  voiceCallImg: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '5%',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  inputField: {
    margin: theme.spacing(1),
    '& input': {
      borderRadius: '20px',
    },
  },
  submitBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2),
    borderRadius: '10px',
  },
  submitedContacts: {
    alignItems: 'center',
  },
  InputLabel: {
    marginLeft: '5px',
  },
  tabCard: {
    width: '-webkit-fill-available',
  },
  btnRadius: {
    borderRadius: '20px',
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
    width: '50%',
  },
  mainContainerBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
    marginBottom: '40px',
  },
  formattedParagraph: {
    fontFamily: 'inherit',
    fontSize: '2rem',
    lineHeight: 'inherit',
    margin: 0,
    padding: 0,
    marginTop: '50px',
    whiteSpace: 'pre-line',
  },
  boldText: {
    fontWeight: 'bolder',
    textAlign: 'center',
    padding: '10px',
  },

  noContactsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  disclaimer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '4%',
  },
});
