import { Http, setCareerCompassCurrentStep, store } from '../../../../core';

/**
 * Constants for the Career Compass module
 */
export const CAREER_COMPASS_CONSTANTS = {
  /**
   * @name STEPS
   * @description The steps for the Career Compass
   */
  STEPS: {
    INTRODUCTION: 'INTRODUCTION GUIDE',
    PROGRAM_PROGRESSION: 'PROGRAM PROGRESSION',
  },

  /**
   * @name STEP_STATE
   * @description The step states for the Career Compass
   */
  STEP_STATE: {
    NOT_STARTED: 'NOT_STARTED',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
  },
};

/**
 * Function to prepare the current step data
 *
 * @param {Object} stepData - The step data
 * @returns {Object} - The prepared current step data
 */
const prepareCurrentStep = (stepData) => {
  const { currentStep, nextStep } = stepData;
  const step = nextStep ?? currentStep;
  return {
    id: step.id,
    name: step.name.toUpperCase(),
    state: step.state,
    title: step.title,
    description: step.description,
  };
};

/**
 * Function to fetch the current step.
 * It will fetch the current step and update the store.
 *
 * @returns {Promise}
 */
export const fetchCurrentStep = async () => {
  return new Promise((resolve, reject) => {
    Http.HYREUS_REQUEST.get('/services/career-compass/v1/current-step')
      .then((response) => {
        if (response?.data?.payload) {
          const currentStep = prepareCurrentStep(response.data.payload);
          store.dispatch(setCareerCompassCurrentStep(currentStep));
          resolve(currentStep);
        }

        resolve({});
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Function to update the current step.
 * It will update the current step with the provided data.
 * It will update the store with the new current step.
 *
 * @param {Object} data - The data to update the current step
 * @returns {Promise}
 */
export const updateCurrentStep = async (data) => {
  return new Promise((resolve, reject) => {
    Http.HYREUS_REQUEST.put('/services/career-compass/v1/current-step?noLoader=true', data)
      .then((response) => {
        if (response?.data?.payload) {
          const currentStep = prepareCurrentStep(response.data.payload);
          store.dispatch(setCareerCompassCurrentStep(currentStep));
          resolve(currentStep);
        }

        resolve({});
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Function to prepare the programs data
 *
 * @param {Array} programsData - The programs data
 * @returns {Array} - The prepared programs data
 */
const preparePrograms = (programsData) => {
  return programsData.map((program) => ({
    id: program.id,
    name: program.name,
    title: program.title,
    description: program.description,
  }));
};

/**
 * Function to fetch all available programs.
 * It will fetch the programs from the API and return them.
 *
 * @returns {Promise<Array>} - The list of programs
 */
export const fetchPrograms = async () => {
  return new Promise((resolve, reject) => {
    Http.HYREUS_REQUEST.get('/services/career-compass/v1/programs')
      .then((response) => {
        if (response?.data?.payload) {
          const programs = preparePrograms(response.data.payload);
          resolve(programs);
        } else {
          resolve([]);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * @name enrollIntoProgram
 * @desc enroll into program
 * @param {String} programId
 * @returns {Promise}
 */
export const enrollIntoProgram = (programId) => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.post(`/programs/${programId}/enroll`)
      .then((_successLog) => {
        resolve(_successLog);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
