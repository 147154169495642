import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { IntlProvider } from 'react-intl';
import locales from '../../../locale';
import { HashRouter as Router } from 'react-router-dom';

import { theme } from '../../services';

const testable = (props) => {
  const themeInstance = new theme();
  if (props.initialThemeWidth) {
    themeInstance.props = { MuiWithWidth: { initialWidth: props.initialThemeWidth } };
  }
  return (
    <Router>
      <IntlProvider locale="en" messages={locales['en']}>
        <MuiThemeProvider theme={themeInstance}>{props.children}</MuiThemeProvider>
      </IntlProvider>
    </Router>
  );
};

export default testable;
