import { useState } from 'react';

const useVoiceCallHook = (props) => {
  const TAB_VALUES = {
    Contacts: 'Contacts',
    Connections: 'Connections',
    CallHistory: 'Call History',
  };

  const [tabActive, setTabActive] = useState(TAB_VALUES.Contacts);

  const handletabActive = (event, newTabValue) => {
    setTabActive(newTabValue);
  };

  return {
    handletabActive,
    tabActive,
    TAB_VALUES,
  };
};

export default useVoiceCallHook;
