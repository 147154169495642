import { createTheme } from '@material-ui/core';
import { CustomTheme } from '@orijinworks/frontend-commons';

const theme = createTheme({
  palette: {
    colors: {
      grey: '#7896A2',
      borderGrey: {
        light: '#EFF0F4',
        dark: '#A5B4BB',
      },
      textGrey: 'var(--grey-110)',
      inputBackground: '#F3F6F9',
      paleBlue: '#EDF7FC',
      babyBlue: '#E4EFF5',
      lightGray: '#F6F8FA',
      lightBlueGrey: '#E2E9EF',
      paleYellow: '#FFF7E3',
      primaryLight: CustomTheme.palette.green.pale,
      paleBlueGray: '#E6ECEE',
      cornflowerBlue: '#609CD8',
      aliceBlue: '#F2FDFF',
      orijinPurple: '#624CA5',
    },
    CallingModal: {
      btnBgColor: {
        backgroundColor: '#051176',
      },
      CallEndIconColor: {
        backgroundColor: '#F00B0B',
      },
    },
    jobZone: {
      colors: {
        zone1: '#7896A2',
        zone2: CustomTheme.palette.primary.dark,
        zone3: CustomTheme.palette.cerulean.main,
        zone4: '#00656C',
        zone5: CustomTheme.palette.yellow.main,
      },
    },
    pdq: {
      level1: {
        main: CustomTheme.palette.cerulean.main,
        dark: CustomTheme.palette.primary.dark,
      },
      level2: {
        main: CustomTheme.palette.primary.main,
        dark: '#2C5754',
      },
      level3: {
        main: CustomTheme.palette.orange.main,
        dark: CustomTheme.palette.orange.main,
      },
    },
    text: {
      primary: CustomTheme.palette.primary.dark,
      secondary: 'var(--grey-110)',
      sub: 'var(--grey-110)',
    },
    primary: {
      peach: '#FFF7E8',
      pastelBlue: '#a1c1ff',
      pastelBlueBg: 'rgba(65, 113, 210, 0.55)',
      battleshipGrey: '#737982',
      lipstick: '#c92563',
      scarlet: '#b00020',
      textGrey: '#4B4B4B',
      lightGrey: '#8E8E8E',
      black: '#1F1F1F',
      grey: '#d8d8d8',
      main: CustomTheme.palette.primary.main,
      sub: CustomTheme.palette.primary.dark,
      light: '#DFE6E9',
      active: CustomTheme.palette.yellow.main,
      darkBlue: '#021843',
      jetBlack: '#000000',
      green: '#66bb6a',
      white: '#ffffff',
      success: '#0f5100',
      themeGreen: '#3ED79E',
      danger: '#840000',
      lightBlue: '#4171d2',
      inverseGradient: 'linear-gradient(to bottom left,#5d6dda 50%, #4190EC)',
      textColor: 'white',
      background: 'var(--grey-10)',
      biscay: '#173f62',
      grayDark: '#919191',
      hippieBlue: '#5686b0',
      mediumBlue: '#0096CA',
      alertRed: '#FF0000',
      red: '#FD2F13',
      mistyRose: '#FFEAEA',
      lightOrange: '#FFA500',
      lightYellow: '#FFFF00',
      darkRed: '#BF4636',
      pale: CustomTheme.palette.blue.pale,
      greyed: '#C9D2DF',
    },
    voice: {
      dismissBtn: {
        backgroundColor: '#0F48B8',
      },
    },
    blue: {
      main: CustomTheme.palette.cerulean.main,
      pale: CustomTheme.palette.blue.pale,
      greyed: CustomTheme.palette.blue.greyed,
      primary: CustomTheme.palette.blue.main,
    },
    green: {
      main: CustomTheme.palette.green.main,
      deep: CustomTheme.palette.green.deep,
      pale: CustomTheme.palette.green.pale,
    },
    orange: {
      main: CustomTheme.palette.orange.main,
      dark: CustomTheme.palette.orange.dark,
      deep: '#E32D4E',
    },
    yellow: {
      main: CustomTheme.palette.yellow.main,
      dark: CustomTheme.palette.yellow.dark,
      deep: CustomTheme.palette.yellow.deep,
      pale: CustomTheme.palette.yellow.pale,
    },
    aqua: {
      extraDeep: '#00656C',
      deep: CustomTheme.palette.aqua.deep,
      pale: '#F6FDFF',
      extraDeep: '#00656C',
    },
    navy: {
      main: CustomTheme.palette.navy.main,
    },
    cyan: {
      dark: '#00656C',
    },
    cerulean: {
      main: CustomTheme.palette.cerulean.main,
      cerulean20: '#0078A2',
    },
    greyScale: {
      grey01: 'var(--grey-110)',
      grey02: 'var(--grey-100)',
      grey03: 'var(--grey-10)',
      grey70: 'var(--grey-70)',
      grey80: 'var(--grey-80)',
      grey10: '#F8F9FC',
      grey90: 'var(--grey-90)',
      lavenderGrey: '#BBC5D5',
      charcoal: '#333639',
      110: 'var(--grey-110)',
      100: 'var(--grey-100)',
      90: 'var(--grey-90)',
      70: 'var(--grey-70)',
      60: 'var(--grey-60)',
      20: 'var(--grey-20)',
      50: 'var(--grey-50)',
      30: 'var(--grey-30)',
    },
    boxShadow: '0px 4px 8px 2px rgba(142, 142, 142, 0.1)',
    contentAreas: {
      work: {
        borderColor: '#FACAC2',
        color: '#F14B32',
        backgroundColor: '#FDF4F2',
      },
      education: {
        borderColor: '#B9CAD9',
        color: '#396993',
        backgroundColor: '#E5F1FF',
      },
      rehab: {
        borderColor: '#B2DACF',
        color: '#EBF7F3',
        backgroundColor: '#017E5B',
      },
      reEntry: {
        borderColor: '#8743DF',
        color: '#D4BDF3',
        backgroundColor: '#F4EEFC',
      },
      enrichment: {
        borderColor: '#D2D4D7',
        color: '#696E77',
        backgroundColor: '#F4F5F6',
      },
      entertainment: {
        borderColor: '#F2C9D9',
        color: '#CB2061',
        backgroundColor: '#FCF3F6',
      },
    },
    secondary: {
      main: CustomTheme.palette.primary.dark,
      light: '#CBDDDF',
    },
    gradient: {
      'occ-gradient': 'linear-gradient(#C4F2FF 0%, #86E6FF 100%)',
    },
  },
  status: {
    danger: '#F2AE00',
  },
  alert: {
    error: CustomTheme.palette.red.main,
  },
  MuiListItemText: {
    primary: 'white',
  },
  typography: {
    ...CustomTheme.typography,
    button: {
      fontWeight: 700,
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '22px',
    },
    bolder: {
      fontWeight: 800,
    },
    bold: {
      fontWeight: 700,
    },
    light: {
      fontWeight: 300,
    },
    medium: {
      fontWeight: 500,
    },
    regular: {
      fontWeight: 400,
    },
    t60: {
      fontSize: 60,
    },
    t44: {
      fontSize: 44,
    },
    t47: {
      fontSize: 47,
    },
    t46: {
      fontSize: 46,
    },
    t36: {
      fontSize: 36,
    },
    t30: {
      fontSize: 30,
    },
    t20: {
      fontSize: 20,
    },
    t22: {
      fontSize: 22,
    },
    t35: {
      fontSize: 35,
    },
    t48: {
      fontSize: 48,
    },
    t14: {
      fontSize: 14,
    },
    t16: {
      fontSize: 16,
    },
    t34: {
      fontSize: 34,
    },
    t25: {
      fontSize: 25,
    },
    t24: {
      fontSize: 24,
    },
    t32: {
      fontSize: 32,
    },
    t18: {
      fontSize: 18,
    },
    t12: {
      fontSize: 12,
    },
    t10: {
      fontSize: 10,
    },
    t9: {
      fontSize: 9,
    },
    t7: {
      fontSize: 7,
    },
  },
});

export default theme;
