import React from 'react';
import PropTypes from 'prop-types';
import { Box, withStyles } from '@material-ui/core';
import styles from './career-compass.styles';
import { Permission } from '../../../../../core';
import { useDocumentTitleHook } from '../../../../shared';
import useCareerCompassHook from './useCareerCompassHook';
import { IntroductionGuide, ProgramSelection } from '../../components';
import { connect } from 'react-redux';

/**
 * Component that renders the Career Compass
 *
 * @component
 * @param {object} props - The component props
 * @returns {JSX.Element}
 */
const CareerCompass = ({ classes, history, ...props }) => {
  useDocumentTitleHook('page.title.career-compass');
  const { handleCurrentStepUpdate, isCurrentStepSelection } = useCareerCompassHook();

  const handleIntroductionComplete = () => {
    handleCurrentStepUpdate('COMPLETED');
  };

  return (
    <Permission {...props}>
      <Box className={classes.root}>
        {!isCurrentStepSelection() ? (
          <IntroductionGuide history={history} onContinue={handleIntroductionComplete} />
        ) : (
          <ProgramSelection selectedLocale={props.selectedLocale} />
        )}
      </Box>
    </Permission>
  );
};

CareerCompass.propTypes = {
  /**
   * The classes object to use for styling that will be injected by withStyles.
   */
  classes: PropTypes.object.isRequired,
  /**
   * The history object to use for navigation.
   */
  history: PropTypes.object.isRequired,
};

const mapStateToProps = ({ app, locale }) => {
  return {
    selectedLocale: locale.locale,
    app,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CareerCompass));
