import { Typography, Box, Button, Card } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import styles from './apds-podcast.style';
import { FormattedMessage, useIntl } from 'react-intl';

const APDSPodcast = (props) => {
  const { classes } = props;
  const intl = useIntl();

  return (
    <Box
      onClick={() => {
        props.handleLaunchClick();
      }}
      className={classes.root}
      tracking-type={props.trackingType}
      tracking-id={props.trackingId}
      id="apds-podcast-container"
      aria-label={intl.formatMessage({ id: 'launchPodcast' })}
    >
      <Card data-cy-name="service-card" className={classes.card}>
        <Box display="flex" className={classes.cardLayout}>
          <Box className={classes.contentArea} component="div" display="flex" flexDirection="column">
            <Box marginBottom="10px" className={classes.centerContainer}>
              <Typography id="apds-podcast-card-title" variant="h3" className={classes.boxTitle}>
                {props.name}
              </Typography>
            </Box>
            <Typography id="apds-podcast-card-desc" className={classes.boxDescription}>
              {props.description}
            </Typography>

            <Button
              className={classes.actionBtn}
              color="secondary"
              variant="contained"
              size="large"
              id="apds-podcast-card-action-btn"
              aria-label={intl.formatMessage({ id: 'playEpisode' })}
            >
              <FormattedMessage id="playEpisode" />
            </Button>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default withStyles(styles)(APDSPodcast);
