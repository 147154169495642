import { useEffect, useRef } from 'react';
import { DateTimeConstants, formatDate, formatTime } from '../../../../shared/services/utility/datetime.utility';

const useMessageThreadHook = (props) => {
  const { threads, totalThreads, loadMoreThreads } = props;
  const loaderRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadMoreThreads();
        }
      });
    });
    const observerTarget = loaderRef.current;

    if (observerTarget && totalThreads > 0) {
      observer.observe(observerTarget);
    }

    return () => {
      if (observerTarget) {
        observer.unobserve(observerTarget);
      }
    };
  }, [loaderRef, totalThreads, loadMoreThreads]);

  const getTime = (datetime) => {
    return datetime ? formatTime(datetime) : '';
  };

  const getDate = (datetime) => {
    return datetime ? formatDate(datetime) : '';
  };

  const shouldShowDate = (date) => {
    return date !== DateTimeConstants.TODAY;
  };

  const shouldShowMore = () => {
    return threads.length < totalThreads;
  };

  return {
    getTime,
    getDate,
    shouldShowDate,
    shouldShowMore,
    loaderRef,
  };
};

export default useMessageThreadHook;
